.block {
  border-radius: 12px;
  background: #fffbff;
  padding: 12px;
  gap: 20px;
  width: 100%;
  max-width: 360px;
  display: flex;
  flex-direction: column;
}

.block__descr {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.block__descr span {
  color: #000;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.08px;
}

.block__descr p {
  color: #000;
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.06px;
}

.block__btns {
  display: flex;
  flex-direction: column;
  gap: 8px;

  a,
  button {
    width: 100%;
  }
}

.navBar {
  width: 100%;
  padding: 4px 8px;
  max-width: 360px;

  button {
    background-color: #fffbff;
  }
}
