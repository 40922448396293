.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  padding: 8px 10px 8px 6px;
  width: fit-content;
  // max-width: 194px;
  // width: 100% !important;
  border-radius: 8px;
  background: #5c53a7;
  backdrop-filter: blur(2px);
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  gap: 4px;

  span {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.08px;
  }

  &:hover {
    background: #ff8e16;

    span {
      color: #fffbff;
    }
  }

  &.active {
    background: #ff8e16;

    span {
      color: #fffbff;
    }
  }
}

.swiper-slide.selected {
  background: #ff8e16;

  span {
    color: #fffbff;
  }
}
