.checkboxMain {
	display: flex;
	margin-top: 28px;
	flex-direction: column;
	gap: 4px;
	> div {
		display: flex;
		gap: 12px;
		border-radius: 8px;
		background: #fffbff;
		align-items: flex-start;
		padding: 16px 8px;
	}
}

.selectBtns {
	display: flex;
	gap: 8px;
}
.checkBox_block {
	display: flex;
	flex-direction: column;
}
.checkBox_items {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 10px;
}
.checkBox_item {
	display: flex;
	flex-direction: row;
	width: 100%;
	gap: 10px;
}
.options {
	display: flex;
	flex-direction: row;
	gap: 20px;
}
.options_item {
	display: flex;
	flex-direction: row;
	gap: 10px;
}

.addInfo_main_block {
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-top: 24px;
}
.checkbox_items {
	display: flex;
	flex-direction: column;
	padding: 12px 0px;
	gap: 4px;
	// height: 250px;
	overflow-y: auto;
	> div {
		border-radius: 8px;
		background: #fffbff;
		width: 100%;
		display: flex;
		padding: 14px 8px;
		gap: 12px;
		align-items: center;
		p {
			color: #1c1c1e;
			font-size: 14px;
			font-weight: 400;
			line-height: 22px; /* 157.143% */
		}
	}
}

.codeInput {
	display: flex;
	align-items: center;
	align-items: flex-end;
	position: relative;
	margin-top: 8px;
	> div > div:last-child {
		max-width: 66px;
	}
	> button {
		margin-bottom: 6px;
		position: absolute;
		left: 75px;
	}
}

.commentTextArea {
	width: 100%;
	height: 250px;
	background-color: #fff;
	border: 1px solid #756cc2;
	border-radius: 8px;
	padding: 12px 12px;
	font-size: 16px;
}
