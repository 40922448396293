.container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin: 10px;
}

.sideBlock {
    box-shadow: 0px 0px 10px -5px #000;
    border-radius: 8px;
    padding: 16px;
}


.unnemberedOrderBlock {
	margin-top: 8px;
	display: flex;
	gap: 12px;
	max-width: calc(100vw - 310px);
	overflow-x: auto;
	align-items: center;

    
	&::-webkit-scrollbar { 
		height: 3px;
	}

	&::-webkit-scrollbar-track {
		background-color: #e4dfff;
		border-radius: 2px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #8f86de;
		border-radius: 2px;
	}
}

@media (max-width: 1139.9px) { 
    .unnemberedOrderBlock { 
        max-width: calc(100vw - 52px); 
    }
}

@media (max-width: 720px) {
    .container {
        display: flex;
        flex-direction: column;
        margin: 0;
    }

    .sideBlock {
        box-shadow: 0px 0px 0 0 #000;
        border: 1px solid #eee;
        padding: 16px;
    } 
}