.photoContainer {
	border-radius: 8px;
	border: 2px dashed var(--core-primary-60, #8f86de);
	display: flex;
	height: 172px;
	min-height: 64px;
	padding: 40px 8px;
	justify-content: center;
	align-items: center;
	width: 100%;
	gap: 8px;
	align-self: stretch;
}

.uploadImageWrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
}

.imageItem {
	width: 172px;
	height: 172px;
	border-radius: 6px;
	background-size: cover;
	background-position: center;
}

.selectedPhotos {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 6px;
	gap: 6px;
}

.mainCont > div:first-child {
	height: 100%;
}

.mainCont {
	display: flex;
	flex-wrap: wrap;
}

.uploadButton {
	color: rgba(92, 83, 167, 0.8);
	font-size: 14px;
	font-weight: 500;
	line-height: 22px; /* 157.143% */
	letter-spacing: 0.08px;
	display: flex;
	gap: 8px;
	align-items: center;
	cursor: pointer;
}

.removeButton {
	border: 2px solid var(--core-primary-60, #8f86de);
	border-radius: 8px;
	padding: 8px 16px;
	background-color: transparent;
	color: var(--core-primary-60, #8f86de);
	cursor: pointer;
	transition:
		background-color 0.3s,
		color 0.3s;
}

.imageButtonWrapper {
	top: 8px;
	right: 8px;
}

.viewer {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
    margin-bottom: 10px;
}

.onePhotoCard {
	position: relative;
	max-height: 106px;
	display: flex;
}

.onePhotoCard img {
    max-width: 106px;
    aspect-ratio: 1/1;
    border-radius: 5px;
} 

.onePhotoCard > :last-child {
    left: 3px;
    bottom: 3px;
} 