.optionSelect {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 4px;
}

.selectLaptop {
	position: absolute;
	top: 58px;
	max-height: 166px;
	z-index: 100000;
	overflow: auto;
	background: var(--core-tertiary-99, #fffbff);
	width: 100%;
	border-radius: 0px 0px 8px 8px;
	border-right: 1px solid #ff8e16;
	border-bottom: 1px solid #ff8e16;
	border-left: 1px solid #ff8e16;
	transition: 0.3s ease;
}

.errorSelectLaptop {
	top: 75px !important;
}

.selectLaptop::-webkit-scrollbar {
	width: 3px;
}

.selectLaptop::-webkit-scrollbar-track {
	background-color: #e4dfff;
	border-radius: 2px;
}

.selectLaptop::-webkit-scrollbar-thumb {
	background-color: #8f86de;
	border-radius: 2px;
}

.optionSelectLaptop {
	display: flex;
	overflow: auto;
	flex-direction: column;
	align-items: center;
	padding-top: 8px;
}

.option {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	padding: 8px;
	border-radius: 8px;
	transition: background-color 0.3s ease;

	&:hover {
		background-color: #f3eeff;
	}
}

.optionLaptop {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	padding: 10px 12px;
	border-radius: 0;
	transition: background-color 0.3s ease;

	&:hover {
		background-color: #f3eeff;

		.optionText {
			color: #5c53a7;
		}
	}

	&:last-child {
		border-radius: 0px 0px 12px 12px;
	}
}

.optionText {
	color: #2c2c2e;
	font-family: 'AvenirNextCyr';
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
}

.selectedOption {
	background-color: #f3eeff;
}

.selectedOption .optionText {
	color: #5c53a7;
}

.unselectedOption {
	background-color: #fffbff;
}

.selectionIndicator {
	width: 26px;
	height: 26px;
	border-radius: 50%;
	border: 1.5px solid #5c53a7;
	display: flex;
	align-items: center;
	justify-content: center;
}

.indicatorActive {
	background-color: #5c53a7;
	position: relative;
	width: 22px;
	height: 22px;
	border-radius: 50%;
}

.indicatorCheck {
	content: '';
	position: absolute;
	left: 8px;
	top: 2px;
	width: 5px;
	height: 13px;
	border: solid #fffbff;
	border-width: 0 1px 1px 0;
	transform: rotate(45deg);
}
