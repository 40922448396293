.bgModal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10002;
	width: 100vw;
	height: calc(var(--vh, 1vh) * 100);
	background: rgba(28, 28, 30, 0.64);
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-end;
}

.modalProfile {
	display: none;
}

.bg {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: flex-end;
	flex-direction: column;
	justify-content: flex-end;
	position: fixed;
	bottom: 0;
}

.navBar {
	display: flex;
	justify-content: flex-end;
	width: 100%;
	padding: 4px 8px;
}

.arrowBar {
	justify-content: space-between;
}

.contModal {
	height: auto;
	overflow-y: auto;
	background: #fffbff;
	width: 100%;
	box-shadow:
		0px 6px 12px -6px rgba(24, 39, 75, 0.12),
		0px 8px 24px -4px rgba(24, 39, 75, 0.08);
	border-radius: 12px 12px 0px 0px;

	&::-webkit-scrollbar {
		width: 2px;
	}

	&::-webkit-scrollbar-track {
		background-color: #e4dfff;
		border-radius: 2px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #8f86de;
		border-radius: 2px;
	}
}

.content {
	width: 100%;
}

.modalTitle {
	color: #1c1c1e;
	font-family: 'AvenirNextCyr';
	font-size: 20px;
	font-weight: 450;
	line-height: 28px;
	letter-spacing: -1.5px;
}

.mainBlock {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 16px 8px 8px 8px;
	gap: 16px;
}
