.layout {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100svh;
}

.layout__aside {
  width: auto;
}

.mobileSideMenu {
  position: absolute;
  z-index: 1010;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}