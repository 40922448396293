.tabsContainer {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    z-index: 1000;
    background-color: #fff;
    transition: border 0.3s;
    border-bottom: 1px solid #ddd;
    position: sticky;
    top: 0;
    flex-wrap: wrap;
    gap: 8px;
}

.sticky {
    border-bottom: 1px solid #ddd;
    position: sticky;
    top: 0;
}