.active,
.non_active {
	position: fixed;
	display: block;
	background-color: #fffbff;
	box-shadow: 0px 18px 88px -4px #18274b24;
	padding: 12px;
}

// декстоп версия если левая часть активна
.wrapper {
	position: relative;

	.active,
	.non_active {
		z-index: 9000;
		transition: right 0.4s ease-in-out;
		top: 0;
		height: 100vh;
		border-radius: 10px 0 0 10px;
	}

	.active {
		right: 0;
		width: 50vw;
	}

	.non_active {
		right: -100vw;
		width: 50vw;
	}
}

// декстоп версия если левая часть НЕактивна
.wrapper__noneActive {
	position: absolute;
	width: 100vw;
	background: #000000a6;
	top: 0;
	height: 100%;
	z-index: 100;
	transition: background 0.1s linear;

	.active,
	.non_active {
		z-index: 9000;
		transition: right 0.4s ease-in-out;
		top: 0;
		height: 100vh;
		border-radius: 10px 0 0 10px;
	}

	.active {
		right: 0;
		width: 50vw;
	}

	.non_active {
		right: -100vw;
		width: 50vw;
	}
}

// мобильная версия
.wrapper_mob {
	position: relative;
}

.wrapper_mobActive {
	position: absolute;
	width: 100vw;
	background: #000000a6;
	top: 0;
	height: 100%;
	z-index: 100;
	transition: background 0.1s linear;

	.active,
	.non_active {
		border-radius: 10px 10px 0 0;
		right: 0;
		height: 60vh;
		z-index: 9000;
		transition: bottom 3s ease-in-out;
	}

	.active {
		width: 100vw;
		bottom: 0;
	}

	.non_active {
		width: 100vw;
		bottom: -60vh;
	}
}

.sideBar__closeBtn {
	position: absolute;
	right: 8px;
	top: -45px;
	width: fit-content;

	button {
		background-color: #fffbff;
	}

	@media (min-width: 1279.9px) {
		left: -45px;
		top: 8px;
	}
}

.sideBar__backBtn {
	position: absolute;
	right: auto;
	top: -45px;
	width: fit-content;

	button {
		background-color: #fffbff;
	}

	@media (min-width: 1279.9px) {
		left: -45px;
		top: 54px;
	}
}
