.content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-x: scroll;
} 

.tabs {
    display: flex;
    gap: 8px;
}

.steps {
    display: flex;
    position: relative;
    margin-top: 12px;
    flex-grow: 1;
    flex-direction: column;
}