.benefits {
  width: 100%;
  padding: 40px 0;
  position: relative;

  @media (min-width: 743.9px) {
    padding: 64px 0;
  }

  @media (min-width: 1279.9px) {
    padding: 80px 0;
  }
}

.benefits__title {
  color: #443a8e;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  text-transform: uppercase;
  margin-bottom: 40px;

  @media (min-width: 567.9px) {
    font-size: 40px;
    line-height: 78px;
    margin-bottom: 48px;
  }

  @media (min-width: 1023.9px) {
    font-size: 56px;
    line-height: 64px;
  }

  @media (min-width: 1279.9px) {
    font-size: 64px;
    line-height: 72px;
    margin-bottom: 64px;
  }
  @media (min-width: 1439.9px) {
    font-size: 72px;
    line-height: 80px;
  }
}

.benefits__list {
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (min-width: 743.9px) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media (min-width: 1279.9px) {
    flex-wrap: nowrap;
  }
}

.benefits__item {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;

  @media (min-width: 743.9px) {
    flex-basis: calc(50% - 16px);
  }

  @media (min-width: 1279.9px) {
    flex-basis: auto;
  }
}

.benefits__item img {
  max-height: 82px;
}

.benefits__item p {
  color: #5c53a7;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.27px;
  text-transform: uppercase;
}

.redSquare {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #fffbff;
  background-color: #de3730;
}

.violetSquare {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #fffbff;
  background-color: #5c53a7;
}

.transparentSquare {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid transparent;
  background: transparent;
}

.item__figure1 {
  display: grid;
  grid-template-rows: 40px 40px;
  grid-template-columns: 40px 40px 40px;
  gap: 2px;
  top: 100px;
}

.item__figure2 {
  display: grid;
  grid-template-rows: 40px 40px;
  grid-template-columns: 40px 40px 40px;
  gap: 2px;
}

.item__figure3 {
  display: grid;
  grid-template-rows: 40px 40px;
  grid-template-columns: 40px 40px;
  gap: 2px;
}

.item__figure4 {
  display: grid;
  grid-template-rows: 40px 40px;
  grid-template-columns: 40px 40px 40px;
  gap: 2px;
}
