.input__descr {
	display: flex;
	flex-direction: column;
	margin-bottom: 8px;
}

.input__descr span {
	color: #149527;
	font-family: 'Montserrat';
	padding-left: 12px;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0.06px;
	transition: 0.1s linear;
	opacity: 0;
	animation: 0.2s anim-opacity linear forwards;
}

.pl0 {
	padding-left: 0 !important;
}

input {
	color: #1c1c1e;
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0.035px;
	transition: 0.2s linear;
	cursor: pointer;
	width: 100%;

	&::placeholder {
		opacity: 1 !important;
	}

	&:-webkit-autofill {
		-webkit-text-fill-color: #000;
		caret-color: #000;
		background-color: transparent !important;
		-webkit-box-shadow: 0 0 0 1000px transparent inset !important;
		transition: background-color 9999s ease-out, color 9999s ease-out !important;
	}
}

.input_title,
.input_error_title {
	color: #1c1c1e;
	font-family: 'Montserrat';
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.035px;
	margin-bottom: 6px;
}

.input_titleBlock {
	display: flex;
	gap: 5px;
}

.input_required {
	color: #ff8e16;
	font-family: 'Montserrat';
	font-size: 16px;
	font-weight: 700;
	line-height: 20px;
	letter-spacing: 0.035px;
}

.input_error_title {
	color: #ba1a1a !important;
	margin-bottom: 0;
}

.InputTopStr {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.InputTopStr_row {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;
}

.InputTopStr_row > p:nth-of-type(1) {
	color: #ff6600;
}

.dispNone {
	display: none;
}

.container_input {
	cursor: pointer;
	min-height: 36px;
	width: 100%;
	padding: 0 12px;
	display: flex;
	gap: 8px;
	align-items: center;
	background-color: #fffbff;
	border: 1px solid #756cc2;
	border-radius: 8px;
	transition: 0.2s linear;
	position: relative;

	&.change {
		border: 1px solid #ff8e16 !important;
		transition: 0.2s linear;

		input::placeholder {
			opacity: 1;
			color: #aeaeb2;
			transition: opacity 0.2s 0.1s;
			-webkit-transition: opacity 0.2s 0.1s;
		}
	}

	&.focus {
		border: 1px solid #ff8e16;
		transition: 0.2s linear;
	}

	&.success {
		border: 1px solid #269e13 !important;
		transition: 0.2s linear;

		input {
			color: #1c1c1e;
			transition: 0.2s linear;
		}
	}

	&.fixed {
		border: 1px solid #443a8e;
		background: #e4dfff;

		input {
			color: #2d2276;
			transition: 0.2s linear;
		}
	}

	&.error {
		border: 1px solid #ba1a1a;
		transition: 0.2s linear;

		input {
			color: #1c1c1e;
			transition: 0.2s linear;
		}
	}
}

.input__label {
	color: #aeaeb2;
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.24px;
	transition: 0.2s linear;
	position: absolute;
	top: 17px;
	transition: top 0.2s;
	-webkit-transition: top 0.2s;
	cursor: pointer;

	&.top {
		color: #8f86de;
		transition: 0.2s linear;
		top: 5px;
		font-size: 12px;
		line-height: 16px;
		transition: top 0.2s;
		-webkit-transition: top 0.2s;
	}
}

.block_input {
	display: flex;
	gap: 4px;
	width: 100%;
	padding-top: 0px;
	flex-direction: row;
	justify-content: space-between;
}

.input__block.password {
	@media (max-width: 340px) {
		max-width: calc(100% - 56px);
	}
}

.input__example {
	color: #149527;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0.06px;
	opacity: 0;
	animation: 0.2s anim-opacity linear forwards;
}

@keyframes anim-opacity {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.dropListArrowUp {
	transform: rotate(180deg);
}

.input__arrow {
	position: absolute;
	right: 12px;
	border-radius: 4px;
	background: #756cc2;
	transition: 0.2s linear;
	&:hover {
		background: #443a8e !important;
	}
}

.input__arrow_change {
	background: #ff8e16 !important;
}

.mainInputs {
	display: flex;
	align-items: center;
	gap: 12px;
	flex-wrap: wrap;
	input {
		font-size: 14px !important;
		font-weight: 400 !important;
		line-height: 20px !important;
		letter-spacing: 0.03px !important;
	}
	.inputs {
		display: flex;
		align-items: center;
		gap: 4px;
	}
	.block_input {
		max-width: 40px;
	}
	.container_input {
		justify-content: center;
		max-width: 70px;
	}
	.dash {
		font-size: 16px;
		font-weight: 400;
		line-height: 16px;
		letter-spacing: 0.079px;
	}
}

.weekItems {
	display: flex;
	gap: 4px;
}
