.containerFormTemplate {
    display: flex;
    flex-direction: column;
    gap: 12px; 
    align-items: flex-start;
} 

.dropzone {
    padding: 20px;
	border: 2px dashed;
	border-color: #5c53a7;
	border-radius: 16px;
	background-color: #e4dfff;
	color: #1c1c1e;
	width: 100%;
	text-align: center;
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.08px;
	position: relative;
	transition: border-color 0.3s linear;
	transition: background-color 0.3s linear;
}

.fileContainer {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px; 
}

.contract_link {
	color: #5c53a7;
	text-decoration: underline;
	cursor: pointer;
}