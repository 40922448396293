.autocomplete-wrapper {
	width: 100%;

	[class*='MuiAutocomplete-noOptions'] {
		padding: 0px !important;
	}
}

.autocomplete-title-block {
	margin-bottom: 8px;
	span {
		font-size: 16px;
		font-family: 'Inter';
	}
}

.autocomplete-input {
	width: 100%;
	border-radius: 8px;

	input {
		padding: 0 !important;
		color: #343c6a !important;
		font-family: 'Inter' !important;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 0.04px;

		&::placeholder {
			color: #939dac;
		}
	}
	.MuiOutlinedInput-root {
		padding: 5px 16px !important;
		border-radius: 8px;
		border: 1px solid;
		border-color: #f5f7fa;
		height: 40px;
		font-family: 'Inter';
		background-color: #f5f7fa;
		transition:
			background-color 0.3s ease,
			border-color 0.3s ease,
			color 0.3s ease;

		&.Mui-focused .MuiOutlinedInput-notchedOutline {
			border: none;
		}
		&.Mui-disabled .MuiOutlinedInput-notchedOutline {
			border: none !important;
		}
		&:hover {
			transition:
				background-color 0.3s ease,
				border-color 0.3s ease,
				color 0.3s ease;
			border-color: #e2e2e2;
			background-color: #ffffff;
		}
	}
}

.autocomplete-input.selected {
	.MuiOutlinedInput-root {
		border-color: #e2e2e2;
		background-color: #ffffff;
	}
}

[class*='MuiAutocomplete-popper'] {
	position: absolute !important;
}

[class*='MuiAutocomplete-paper'] {
	color: #000000 !important;
}

.loading-text {
	color: #000000;
}
