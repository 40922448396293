.header-main {
	width: 100%;
	transition: all 0.4s;

	@media (max-width: 567.9px) {
		.header__nav {
			display: none;
		}
	}
}

.header-one {
	width: 100%;
	transition: all 0.2s;
	background-color: #fff;
	border-radius: 12px;
	margin-top: 8px;

	@media (max-width: 567.9px) {
		margin-top: 12px;
	}
}

.header__container {
	width: 100%;
	max-width: calc(100% - 24px);
	margin-right: auto;
	margin-left: auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-sizing: border-box;
	position: relative;
	z-index: 2;

	@media (min-width: 567.9px) {
		max-width: calc(100% - 28px);
	}

	@media (min-width: 743.9px) {
		max-width: calc(100% - 48px);
	}

	@media (min-width: 1023.9px) {
		max-width: calc(100% - 64px);
	}

	@media (min-width: 1281px) {
		max-width: calc(100% - 140px);
	}

	@media (min-width: 1439.9px) {
		max-width: 1320px;
	}
}

.header__logo {
	padding: 0px 12px;

	a {
		display: flex;
		align-items: center;
	}

	img {
		width: auto;
	}

	@media (max-width: 1279px) {
		padding: 8px 12px;
	}
}

.header__burger {
	@media (min-width: 1280px) {
		display: none;
	}
}

.burger__block {
	width: 46px;
	text-align: center;
	display: inline-block;
	border-radius: 50%;
	padding: 8px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	transition: all 0.3s;
	margin-right: 16px;

	&:hover {
		background: rgba(240, 247, 252, 0.2);
	}
}

.burger {
	position: relative;
	width: 32px;
	height: 32px;
	display: block;
	color: #5c53a7;
	transition: 0.3s;

	&:hover {
		color: #4f4894;
		transition: 0.3s;
	}

	&:before,
	&:after,
	> span {
		content: '';
		position: absolute;
		background: currentColor;
		transition: all 0.2s;
		width: 24px;
		height: 2px;
		left: 4px;
		border-radius: 3px;
	}

	&:before {
		top: 8px;
	}

	&:after {
		bottom: 8px;
	}

	> span {
		top: 50%;
		margin-top: -1px;
		width: 24px;
	}
}

.header__wrap {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	position: fixed;
	z-index: 10;
}

.header__nav {
	display: flex;
	justify-content: flex-end;
	padding-right: 12px;

	ul {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 12px;
	}

	a {
		padding: 20px 16px;
		border-bottom: 2px solid transparent;
		transition: 0.3s;
		color: #1c1c1e;
		text-align: center;
		font-family: Raleway;
		font-size: 20px;
		font-weight: 500;
		line-height: 24px;
		text-transform: uppercase;

		&:hover {
			border-bottom: 2px solid #5c53a7;
			transition: 0.3s;
			color: #5c53a7;
		}
	}

	a.active {
		border-bottom: 2px solid #5c53a7;
		transition: 0.3s;
		color: #5c53a7;
	}

	@media (max-width: 1279px) {
		padding-right: 8px;

		a {
			padding: 20px 8px;
			font-size: 14px;
			line-height: 18px;
		}
	}
}

.links__social {
	display: flex;
	flex-direction: row;
	background-color: #fff;
	border-radius: 12px;
	padding: 4px 16px;
	gap: 8px;
	@media (max-width: 768px) {
		display: none;
	}

	a {
		&:hover {
			transition: all 0.5s ease-out;
		}
	}
}

.mobile_links_social {
	@media (max-width: 768px) {
		display: flex;
	}
}

.links__contacts {
	display: flex;
	flex-direction: row;
	gap: 12px;

	a {
		border-radius: 8px;
		background: #5c53a7;
		color: #fffbff;
		padding: 14px 16px;
		font-size: 16px;
		font-weight: 500;
		line-height: 20px;
		letter-spacing: 0.08px;
		text-transform: uppercase;
		transition: 0.3s;

		&:hover {
			background: #443a8e;
		}

		&:active {
			background: #2d2276;
		}

		&:disabled {
			background: #8e8e93;
		}
	}

	@media (max-width: 768px) {
		width: 100%;
	}

	@media (max-width: 567.9px) {
		a {
			padding: 8px 8px;
			font-size: 14px;
			line-height: 16px;
		}
	}

	@media (max-width: 350.9px) {
		a {
			padding: 8px 8px;
			font-size: 12px;
			line-height: 14px;
		}
	}
}

.links__contacts.col {
	flex-direction: column;
	align-items: center;
	padding: 0 10px;

	a {
		text-transform: none;
	}
}

.header-two {
	padding: 0 1px;
	z-index: 1;
	position: absolute;
	top: 86px;
	transition: 0.5s;

	&.hide {
		top: 12px;
		transition: 0.5s;
	}

	@media (max-width: 567px) {
		&.hide {
			top: 16px;
		}
	}
}

.burgerMenu {
	opacity: 0;
	visibility: hidden;
	width: 100vw;
	height: calc(var(--vh, 1vh) * 100);
	position: absolute;
	top: 0;
	right: 0;
	background: rgba(32, 26, 26, 0.94);
	z-index: 20;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding: 8px;
	transition: 0.3s;

	.header__nav {
		display: flex;
		justify-content: flex-start;
		padding: 0;

		ul {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			width: 100%;
			gap: 0;
			margin-top: 12px;
		}

		a {
			padding: 10px 16px;
			border-bottom: 2px solid transparent;
			width: 100%;

			&:hover {
				border-bottom: 2px solid #5c53a7;
				background: #5c53a7;
				color: #fffbff;
			}
		}

		a.active {
			border-bottom: 2px solid #5c53a7;
			background: #5c53a7;
			color: #fffbff;
		}
	}

	.links__social {
		flex-direction: column;
		padding: 8px;
		gap: 24px;
	}

	.links__contacts a {
		display: flex;
		justify-content: center;
		width: 100%;
		margin: 0 12px;
	}

	.header__logo {
		padding: 0 0 12px 0;
		border-bottom: 1px solid #443a8e;

		img {
			height: 48px;
			margin: 0 auto;
		}
	}
}

.burgerMenu__container {
	display: flex;
	flex-direction: row;
	gap: 12px;
	width: 100%;
	justify-content: flex-end;
	transform: translate3d(100%, 0, 0);
	transition: transform 0.3s ease-in-out;
	will-change: transform;
}

.burgerMenu__stack {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;

	button {
		background: #fffbff;
	}
}

.burgerMenu__menu {
	background: #fffbff;
	border-radius: 16px;
	padding: 12px 0;
	width: 100%;
	max-width: 280px;
	min-height: 360px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

@media (min-width: 1279px) {
	.burgerMenu {
		display: none;
	}
}

.burgerMenu.show {
	opacity: 1;
	visibility: visible;
	transition: 0.3s;
}
.burgerMenu__container.show {
	transform: translate3d(0, 0, 0);
}

.header__block {
	display: flex;
	align-items: center;
	margin-right: 12px;

	@media (max-width: 1279px) {
		display: none;
	}
}
