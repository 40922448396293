.terminals {
	padding-top: 80px;
	@media (min-width: 768px) {
		padding-top: 120px;
	}
	@media (min-width: 1281px) {
		max-width: calc(100vw - 140px);
	}
	display: flex;
	flex-direction: column;
	align-items: center;
	.swiper-wrapper {
		margin-bottom: 8px;
	}

	.swiper-slide {
		padding: 32px 32px 40px 32px;
		background: #ffffff;
		width: 100% !important;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 36px;
		border-radius: 24px;
	}
}

@media (max-width: 768px) {
	.mobileNoneBlock {
		display: none !important;
	}
	.mobileBlock {
		width: 100% !important;
	}
}

.terminals__slider {
	position: relative;
	@media (max-width: 1281px) {
		max-width: calc(100vw - 48px);
	}

	@media (min-width: 1281px) {
		width: 100%;
		max-width: 1320px;
	}
}

.swiper-button-next {
	cursor: pointer;
	position: relative;
	width: 54px;
	height: 54px;
	border-radius: 10px;
	background-color: #FF8E16;
	left: calc(100% - 54px);
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;

	&::before {
		content: '';
		height: 28px;
		width: 28px;
		background: url(./imgs/arrowRight.svg);
		background-size: cover;
	}

	&:hover {
		background-color: #b56100;
	}
	@media (max-width: 768px) {
		border-radius: 8px;
		width: 36px;
		height: 36px;
		left: calc(100% - 37px);
		&:hover {
			background-color: #FF8E16;
		}
		&::before {
			content: '';
			height: 20px;
			width: 20px;
		}
	}
}

.swiper-button-prev {
	cursor: pointer;
	position: absolute;
	width: 54px;
	height: 54px;
	background-color: #FF8E16;
	border-radius: 10px;
	right: 66px;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;

	&::before {
		content: '';
		height: 28px;
		width: 28px;
		background: url(./imgs/arrowLeft.svg);
		background-size: cover;
	}

	&:hover {
		background-color: #b56100;
	}
	@media (max-width: 768px) {
		border-radius: 8px;
		right: 48px;
		width: 36px;
		height: 36px;
		&:hover {
			background-color: #FF8E16;
		}
		&::before {
			content: '';
			height: 20px;
			width: 20px;
		}
	}
}

.swiper-button-disabled {
	background: rgba(255, 142, 22, 0.40);
	cursor: default;

	&:hover {
		background-color: rgba(255, 142, 22, 0.40);
	}
}

.slide__title {
	color: #170362;
	font-family: 'Montserrat';
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 32px;
}

.slide__flex {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
}

.slide__stack {
	display: flex;
	flex-direction: column;
	gap: 26px;
	width: 50%;
}

.slide__info {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.slide__descr {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.stack__title {
	color: #4b4299;
	font-family: Montserrat;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 26px;
	letter-spacing: 0.02px;
}

.stack__text {
	color: #4b4299;
	font-family: Montserrat;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 26px;
	letter-spacing: 0.02px;
}

.stack__textWithIcon {
	display: flex;
	flex-direction: row;
	gap: 8px;
	align-items: center;

	p {
		color: #4b4299;
		font-family: Montserrat;
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 26px;
		letter-spacing: 0.02px;
	}
}

.slide__list {
	display: flex;
	flex-direction: column;
	gap: 12px;

	li {
		list-style-type: disc;
	}
}
