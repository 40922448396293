.btnIcon {
  border-radius: 8px;
  width: 100%;
  padding: 16px;
  width: fit-content;
  cursor: pointer;
  background: #5c53a7;
}

.custom {
  border-radius: 8px !important;
}

.noneBg {
  background: transparent;
  border: 1px solid #ba1a1a;
}

.blueBorder{
  background: white;
  border: 1px solid #5C53A7;
}

.noneBgSect {
  background: transparent; 
  border: 1px solid #5C53A7;
}

.green {
  background: #149527;
}

.whiteBg {
  background: #fffbff;
}

.blackBg {
  background: #000000;
}

.violetBg {
  background: #8f86de;
}

.blueBg {
  background: #2d2276;
}

.p-6 {
  padding: 6px;
}

.p-8 {
  padding: 8px;
}

.p-12 {
  padding: 12px;
}

.p-16 {
  padding: 16px;
}

.refresh {
  background: #ff9500;
}

.delete {
  background: #ba1a1a;
  color: #fffbff;
  transition: 0.3s;

  &:hover {
    background: #9c1313;
  }

  &:active {
    background: #860f0f;
  }

  &:disabled {
    background: #8e8e93;
  }
}
