.containerFormTemplate {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 10px;
} 

@media (max-width: 720px) {
	.containerFormTemplate {
		display: flex;
		flex-direction: column;
	}
}

.schedule {
	margin-top: 12px;
}

.scheduleContainer {
    margin-top: 8px;
	display: flex;
	flex-direction: column;
	gap: 8px;
}

