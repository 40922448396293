.footer {
	padding: 40px 0 24px 0;
	width: 100%;

	@media (min-width: 743.9px) {
		padding: 64px 0 24px 0;
	}

	@media (min-width: 1279.9px) {
		padding: 80px 0 24px 0;
	}
}

.footer__block {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	@media (min-width: 1023.9px) {
		flex-direction: row-reverse;
		gap: 24px;
	}
}

.footer__modal {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.footer__map {
	display: flex;
	flex-direction: column;
	gap: 24px;
	margin-bottom: 24px;
	width: 100%;

	button {
		width: 100%;
		background: #fffbff;
	}

	@media (min-width: 567.9px) {
		align-items: flex-end;

		button {
			width: fit-content;
		}
	}

	@media (min-width: 1023.9px) {
		width: 50%;
		margin-bottom: 80px;
	}
}

// ymaps {
//   border-radius: 24px;
// }

[class*='copyrights-pane'] {
	display: none !important;
}

.footer__contacts {
	margin-bottom: 80px;

	button {
		width: 100%;
	}
	span {
		color: #fff;
		font-size: 28px;
		font-weight: 500;
		line-height: 36px;
		letter-spacing: 0.04px;
		display: block;
		margin-bottom: 20px;
	}

	@media (min-width: 567.9px) {
		button {
			width: auto;
		}
	}

	@media (min-width: 1023.9px) {
		width: 50%;
	}
}

.footer__contacts_btn {
	display: flex;
	align-items: center;
	justify-content: flex-end;

	@media (min-width: 743.9px) {
		justify-content: flex-start;
	}
}

.contacts__list {
	display: flex;
	flex-direction: column;
	gap: 12px;
	color: #fff;
	font-size: 14px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0.02px;
	margin-bottom: 32px;
	> p {
		display: flex;
		gap: 4px;
		a {
			color: #fff;
		}
	}

	@media (min-width: 360px) {
		font-size: 16px;
	}

	@media (min-width: 399.9px) {
		font-size: 18px;
	}
}

.footer__copyright {
	color: #d1d1d6;
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0.08px;
	text-align: center;
	display: flex;
	flex-direction: column;
	gap: 24px;

	div {
		display: flex;
		align-items: center;
		gap: 16px;
		line-height: 24px;
		justify-content: center;

		p {
			color: #d1d1d6;
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			letter-spacing: 0.08px;
		}

		img {
			max-height: 32px;
			width: 100%;
		}
	}

	p {
		color: #d1d1d6;
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		letter-spacing: 0.035px;
	}

	@media (min-width: 743.9px) {
		flex-direction: row-reverse;
		justify-content: space-between;
		align-items: center;
	}
}

.modal {
	padding: 16px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	gap: 24px;

	span {
		color: #fff;
		font-size: 20px;
		font-weight: 600;
		line-height: 28px;

		@media (min-width: 743.9px) {
			font-size: 24px;
			line-height: 32px;
		}
	}
}

.modal__form {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 100%;

	div {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 12px;
	}

	label {
		color: #fffbff;
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		text-transform: uppercase;
	}

	input {
		border-radius: 8px;
		background: #fffbff;
		padding: 18px 8px 18px 16px;
		color: #000;
		font-size: 18px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: 0.18px;

		&::placeholder {
			color: #787878;
			opacity: 1;
			text-transform: uppercase;
		}
	}

	textarea {
		border-radius: 8px;
		background: #fffbff;
		padding: 8px;
		color: #000;
		font-size: 18px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: 0.18px;

		&::placeholder {
			color: #787878;
			opacity: 1;
			text-transform: uppercase;
		}
	}

	button {
		margin-top: 4px;
	}
}
