.mainStyle {
	border-radius: 2px;
	border: 1px solid #8e8e93;
}

.angular-HR,
.angular-HL,
.angular-BR,
.angular-BL {
	width: 40px;
	height: 40px;
}

//! Для крыши
.angular-T_LL,
.angular-T_LR,
.angular-T_RL,
.angular-T_RR {
	position: absolute;
	width: 40px;
	height: 40px;
	z-index: 0;
	background: #eceaee;
}
.angular-T_LL {
	top: 22px;
	left: 42px;
}
.angular-T_LR {
	top: 22px;
	right: 0;
	z-index: 5;
}
.angular-T_RL {
	bottom: 0;
	left: 42px;
}
.angular-T_RR {
	bottom: 0;
	right: 0;
}
//!_____

//! U -
.mainPanel_U {
	border-radius: 2px;
	border: 1px solid #8e8e93;
	width: 40px;
	height: 160px;
}
//!_____

.beam-horizontal-H,
.beam-horizontal-B {
	width: 100px;
	height: 40px;
}

.miniSquare {
	width: 18px;
	height: 18px;
}

.maxWidthHeight {
	width: 64px;
	height: 32px;
}

.beam-Maxhorizontal-B,
.beam-Maxhorizontal-H {
	width: 202px;
	height: 40px;
}

.maxHeight-R,
.maxHeight-L {
	width: 40px;
	height: 162px;
}

.maxWidth {
	width: 64px;
	height: 18px;
}

.dashed_floor {
	width: 254px;
}

.dashed_bottom {
	width: 327px;
}

.dashed_floor,
.dashed_bottom {
	height: 80px;
	border-top: 1px dashed #8e8e93;
	border-bottom: 1px dashed #8e8e93;
	border-left: 1px dashed #8e8e93;
}

.beam-vertical-L,
.beam-vertical-R {
	width: 40px;
	height: 80px;
}

.svgActive svg {
	fill: #ff8e16;
}

.absolute svg {
	position: absolute;
}

.absoluteBottomLeft svg {
	position: absolute;
	bottom: 0;
}

.absoluteTopRight svg {
	position: absolute;
	right: 0;
}

.absoluteBottomRight svg {
	position: absolute;
	bottom: 0;
	right: 0;
}

.mainSvgClass {
	max-height: 52px;
}

.visabilityNone {
	border: none !important;
}

.mainPanel {
	height: 80px;
	width: 100px;
}

.dashed_rec {
	width: 84px;
	height: 20px;
	border: 1px dashed #8e8e93;
	background: #ece9ee;
}

.active {
	background: #ff8e16 !important;
}
.choosen {
	background: #5c53a7 !important;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
}
.doorHingeL1,
.doorHingeL2,
.doorHingeL3,
.doorHingeL4,
.doorHingeR1,
.doorHingeR2,
.doorHingeR3,
.doorHingeR4 {
	// position: absolute;
	// width: 40px;
	// height: 20px;
	// border-radius: 2px;

	width: 32px;
	height: 16px;
	border-radius: 2px;
	z-index: 2;
	background: #eceaee;
	border: 1px solid #8e8e93;
}
.rodCamH1,
.rodCamH2,
.rodCamH3,
.rodCamH4,
.rodCamL1,
.rodCamL2,
.rodCamL3,
.rodCamL4 {
	width: 10px;
	height: 10px;
	z-index: 2;
	border-radius: 50%;
	background: #c7c7cc;
}
.rodCamH1,
.rodCamH2 {
	margin-right: 20px;
}

.rodCamH2 {
	margin-right: 40px;
}

.rodCamH3 {
	margin-right: 20px;
}

//! Нижние кулачки

.rodCamL1,
.rodCamL2 {
	margin-right: 20px;
}

.rodCamL2 {
	margin-right: 40px;
}

.rodCamL3 {
	margin-right: 20px;
}
.rod {
	height: 200px;
	width: 5px;
	background: #c7c7cc;
	z-index: 1;
}
.rod:nth-child(1),
.rod:nth-child(2) {
	margin-right: 25px;
}

.rod:nth-child(2) {
	margin-right: 44px;
}

.rod:nth-child(3) {
	margin-right: 25px;
}

.handle {
	height: 8px;
	width: 20px;
	background: #c7c7cc;
	z-index: 1;
}
.handle:nth-child(1),
.handle:nth-child(2) {
	margin-right: 10px;
}

.handle:nth-child(2) {
	margin-right: 44px;
}

.handle:nth-child(3) {
	margin-right: 10px;
}
.sealL {
	top: 0;
	border: 2px solid transparent;
	height: 162px;
	width: 100px;
}
.sealR {
	border: 2px solid transparent;
	height: 162px;
	width: 100px;
}
.active_seal {
	border: 3px solid #ff8e16;
	// z-index: 3;
	background: transparent !important;
}
.choosen_seal {
	border: 3px solid #5c53a7;
	// z-index: 3;
	background: transparent !important;
}

//вилочные карманы правый и левый
.Left_forkpocket_LL,
.Right_forkpocket_LR,
.Left_forkpocket_RL,
.Right_forkpocket_RR {
	width: 80px;
	height: 30px;
}
