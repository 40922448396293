.header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.header__logo {
  background-color: #fffbff;
  border-radius: 12px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 744px) {
  .header {
    justify-content: flex-start;
  }
}

.logo {
  height: 40px;
}
