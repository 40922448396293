.block {
  border-radius: 12px;
  background: #fffbff;
  padding: 12px;
  gap: 20px;
  width: 100%;
  max-width: 360px;
  display: flex;
  flex-direction: column;
}

.block p {
  color: #000;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.06px;
}
