.card { 
	background-color: #fff;
	border-radius: 16px;
	width: 100%;
	transition: 0.3s ease;
	height: 100%;
	width: 100%;

	&:hover {
		cursor: pointer;
		box-shadow: 0px 0px 6px #443a8e80;
		transition: 0.3s ease;
	}
}
