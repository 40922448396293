.mainBlock {
	display: flex;
	gap: 2px;
	margin-top: 8px;
	position: relative;
	> div:first-child,
	> div:last-child {
		justify-content: flex-end;
	}
	p {
		color: #8e8e93;
		font-size: 12px;
		font-weight: 500;
		line-height: 20px; /* 166.667% */
	}
}

.bottomLast {
	position: absolute;
	right: 0;
	margin-top: 42px;
}

.firstFrontAngP {
	position: relative;
	padding-left: 21px;
}

.floor_dashed_prat {
	position: absolute;
	right: 0;
	margin-top: 62px;
}

.mapColumn {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 2px;
	align-items: center;
	> .doubleColumn:first-child {
		align-items: flex-start;
	}
	> .doubleColumn:last-child {
		align-items: flex-end;
	}
}

.doubleColumn {
	display: flex;
	position: relative;
	gap: 14px;
	width: 100%;
	justify-content: flex-end;
}

.floor_dashed_part {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	> div:first-child {
		margin-top: 22px;
		position: absolute;
		right: 0;
	}
}

.blockDouble {
	position: relative;
	gap: 2px;
	display: flex;
	flex-direction: column;
}

.relative {
	position: relative;
}

.firstColumnP {
	display: flex;
	flex-direction: column;
	justify-content: space-between !important;
	padding-right: 13px;
	> p:first-child {
		margin-top: 18px;
	}
}

.firstPColumn {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	height: 100%;
}

.front_door_mainPanels {
	display: flex;
	gap: 2px;
}

.front_door_topP {
	display: flex;
	width: 100%;
	justify-content: space-around;
	margin-top: 2px;
}

.firstVerticalSymbol {
	display: flex;
	align-items: center;
	gap: 14px;
	width: 100%;
	justify-content: space-between;
}

.font_door_firtsP {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.leftLoops_main {
	height: 100%;
	position: relative;
	> div:last-child {
		bottom: 0;
	}
}

.leftTopAngular,
.leftBottomAngular,
.rightTopAngular,
.rightBottomAngular {
	position: relative;

	> div:first-child {
		position: absolute;
		background: #ece9ee;
	}
}

.leftTopAngular > div:first-child {
	left: 0;
	top: 0;
}

.leftBottomAngular > div:first-child {
	left: 0;
	bottom: 0;
}

.rightTopAngular > div:first-child {
	right: 0;
	top: 0;
}

.rightBottomAngular > div:first-child {
	right: 0;
	bottom: 0;
}

.dashedBlock {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	> div:first-child {
		position: absolute;
	}
}

.leftLoops {
	display: flex;
	flex-direction: column;
	position: absolute;
	gap: 12px;
	left: 14px;
	> div {
		height: 20px !important;
	}
}

.firstRow {
	display: flex;
}
.doorHingesL {
	position: absolute;
	top: 85px;
	// left: 50px;
	left: 67.5px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	// height: 100px;
}
.doorHingesR {
	position: absolute;
	top: 85px;
	right: 25px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	// height: 100px;
}

.rodCamsH {
	position: absolute;
	top: 50px;
	display: flex;
	flex-direction: row;
	left: 126px;
	// gap: 20px;
	// height: 100px;
}
.rodCamsL {
	position: absolute;
	top: 247px;
	display: flex;
	flex-direction: row;
	left: 126px;
	// gap: 20px;
	// height: 100px;
}
.rods {
	position: absolute;
	display: flex;
	flex-direction: row;
	top: 55px;
	// gap: 25px;
	// left: 53.5px;
	left: 129px;
}
.handlers {
	position: absolute;
	display: flex;
	flex-direction: row;
	top: 155px;
	// gap: 25px;
	// left: 53.5px;
	// left: 29px;
	left: 114px;
}

//! TTEST
.mainBlock_new {
	display: flex;
	flex-direction: column;
	gap: 2px;
	margin-top: 8px;
	position: relative;
}
.mainBlock_U {
	display: flex;
	flex-direction: column;
	gap: 2px;
	margin-top: 8px;
	position: relative;
}
.mapRow_new {
	display: flex;
	align-items: center;
	gap: 2px;
	z-index: 1;
}
.mapRowNumber_new {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	padding-left: 40px;
	gap: 40px;
}
.mapNoOptionRow {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 160px;
}
.mapRowNumber_U {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	padding-left: 40px;
	gap: 0px;
}
.topNumber_new {
	color: #8e8e93;
	font-size: 12px;
	font-weight: 500;
	line-height: 20px; /* 166.667% */
	width: 40px; /* Adjust as needed */
	text-align: center;
}
.topNumber_new:nth-of-type(1) {
	display: none;
}

.sideLabel_new {
	color: #8e8e93;
	font-size: 12px;
	font-weight: 500;
	line-height: 20px; /* 166.667% */
	width: 40px; /* Adjust as needed */
	text-align: center;
}
.seals {
	position: absolute;
	top: 64px;
	left: 84px;
	display: flex;
	flex-direction: row;
	gap: 2px;
	z-index: 0;
}

.forkPocketsContainer_20 {
	position: absolute;
	display: flex;
	top: 233px;
	left: 155px;
	gap: 123px;
}

.forkPocketsContainer_40 {
	position: absolute;
	display: flex;
	top: 233px;
	left: 155px;
	gap: 633px;
}
