.permissionsTable {
	width: 100%;
	margin: 0 auto;
	max-height: calc(100vh - 320px);
	overflow: auto;

	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		background-color: #ffdebc;
		border-radius: 4px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #ff8e16;
		border-radius: 4px;
	}
}

.header {
	display: flex;
	align-items: center;
	padding: 10px;
	background-color: #2a2a3b;
	color: white;
	position: sticky;
	top: 0;
	z-index: 10;
}

.headerTitle {
	display: flex;
	flex: 1;
}

.headerBtns {
	margin-left: 12px;
	display: flex;
	justify-content: space-around;
	flex: 1;
}

.headerButton {
	background-color: #6f52ed;
	border: none;
	color: white;
	padding: 10px 20px;
	cursor: pointer;
}

.table {
	width: 100%;
	margin-bottom: 50px;
}

.row {
	display: flex;
	align-items: center;
	padding: 10px;
	border-bottom: 1px solid #ccc;
}

.sectionName {
	flex: 1;
	padding: 5px;
	font-size: 14px;
}

.checkboxes {
	display: flex;
	justify-content: space-around;
	flex: 1;
}

.checkbox {
	width: 20px;
	height: 20px;
}
