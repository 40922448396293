.counter {
  border-radius: 8px;
  background: #fffbff;
  padding: 4px 12px;
  color: #000;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.06px;
  width: fit-content;
}
