.preloader {
	display: flex;
	justify-content: center;
	align-items: center;
	// height: 75vh;
	height: inherit;
}

.spinner {
	width: 80px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.bounce1,
.bounce2,
.bounce3 {
	width: 18px;
	height: 18px;
	background-color: #ff8e16;
	border-radius: 100%;
	display: inline-block;
	animation: bounce 1.4s infinite ease-in-out;
}

.bounce2 {
	animation-delay: 0.2s;
}

.bounce3 {
	animation-delay: 0.4s;
}

.small {
	width: 12px;
	height: 12px;
}

@keyframes bounce {
	0%,
	80%,
	100% {
		transform: scale(0);
	}
	40% {
		transform: scale(1);
	}
}
