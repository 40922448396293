.containerFormTemplate {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

@media (max-width: 720px) {
    .containerFormTemplate {
        display: flex;
        flex-direction: column;
    }
}