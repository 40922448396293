.entity_list {
	background: #F5F7FA;
	display: flex;
	flex-direction: column;
	gap: 8px;
	height: calc(100% - 94px);

	@media (min-width: 1139.9px) {
		height: calc(100% - 80px);
	}
}

.entity_item {
	width: 49%;

	@media (max-width: 1024px) {
		width: 100%;
		flex-grow: 1;
	}
}

.entity_list_withSlider {
	background: #101a28;
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 16px 0 0 0;

	@media (min-width: 639.9px) {
		padding: 16px;
	}
}

.entity__collection {
	height: 100%;
	background: #F5F7FA;
	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		background-color: #ffdebc;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #ff8e16;
	}
}