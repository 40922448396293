.bgModal {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10002;
	position: fixed;
	width: 100vw;
	height: calc(var(--vh, 1vh) * 100);
	background: rgba(30, 30, 30, 0.56);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.bg {
	height: 100%;
	width: calc(100% - 16px);
	display: flex;
	max-width: 500px;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}

.navBar {
	display: flex;
	justify-content: flex-end;
	width: 100%;
	padding: 4px 20px;
}

.contModal {
	height: auto;
	overflow-y: auto;
	background: #fffbff;
	width: 100%;
	box-shadow:
		0px 6px 12px -6px rgba(24, 39, 75, 0.12),
		0px 8px 24px -4px rgba(24, 39, 75, 0.08);
	border-radius: 24px;
}

.content {
	width: 100%;
}

.secondary {
	.contModal {
		background: #263141;
	}
}

.adminPanel {
	.bg {
		width: 100%;
		max-width: 540px;
		justify-content: flex-end;
		position: absolute;
		bottom: 15%;
	}

	.navBar {
		padding: 4px 8px;
	}

	.contModal {
		padding-top: 20px;
	}
}

.bigSize .bg {
	max-width: 568px;
}
