.menuMobile {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: rgba(16, 28, 43, 0.96);
  backdrop-filter: blur(4px);
}

li {
  list-style-type: none;
}

.menuMobile__item {
  min-width: 56px;
  max-width: 56px;
  text-align: center;

  a {
    padding: 7px 0px;
    display: flex;
    flex-direction: column;

    &.activeLink {
      background-color: #1d3149;
    }
  }
}

.menuMobile__item__text {
  margin-top: 4px;
  color: #fffbff;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.24px;
}
