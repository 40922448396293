.contactUs {
  border-radius: 24px;
  background: rgba(92, 83, 167, 0.48);
  box-shadow: 0px 8px 18px -6px rgba(190, 38, 76, 0.12),
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(12px);
  padding: 32px;
  width: 100%;
  max-width: 448px;
  margin: 0 auto;

  @media (max-width: 567.9px) {
    padding: 12px;
  }
}

.contactUs__form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.contactUs__title {
  color: #fff;
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  text-transform: uppercase;

  @media (max-width: 567.9px) {
    font-size: 22px;
  }
}

.contactUs__input {
  display: flex;
  flex-direction: column;
  gap: 12px;

  label {
    color: #fffbff;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
  }

  input {
    border-radius: 8px;
    background: #fffbff;
    padding: 18px 8px 18px 16px;
    color: #000;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.18px;

    &::placeholder {
      color: #787878;
      opacity: 1;
      text-transform: uppercase;
    }
  }
}

.contactUs__btn {
  display: flex;
  flex-direction: column;
  gap: 20px;

  p {
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
  }

  button {
    width: 100%;
    text-transform: uppercase;
  }
}
