.advantages__list {
	display: flex;
	flex-wrap: wrap;
	gap: 12px;
	padding: 0 24px;
	@media (max-width: 768px) {
		flex-wrap: nowrap;
		padding: 0;
		flex-direction: column;
	}
}

.advantages {
	padding-top: 80px;
	@media (min-width: 768px) {
		padding-top: 120px;
	}
}

.advantages__item {
	width: calc(50% - 6px);
	min-height: 320px;
	box-sizing: border-box;
	padding: 32px;
	border-radius: 24px;
	background: rgba(92, 83, 167, 0.48);
	box-shadow:
		0px 8px 18px 0px rgba(190, 38, 76, 0.12),
		0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	backdrop-filter: blur(12px);
	display: flex;
	flex-direction: column;
	gap: 12px;
	@media (max-width: 768px) {
		width: 100%;
		min-height: auto;
	}
}

.advantages__title {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 12px;
	h5 {
		color: #fff;
		font-family: Montserrat;
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: 32px;
	}
}

.advantages__text {
	color: #fff;
	font-family: Montserrat;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0.02px;
}

.advantages__map {
	width: calc(50% - 6px);
	min-height: 320px;
	border-radius: 24px;
	overflow: hidden;

	@media (max-width: 768px) {
		width: 100% !important;
		max-width: 99% !important;
	}
}

[class*='-balloon'] {
	border-radius: 8px;

	ymaps {
		border-radius: 8px;
	}
}
