.notificationBlock {
  width: calc(100% - 32px);
  max-width: 360px;
  border-radius: 12px;
  background: #fdfcff;
  position: fixed;
  z-index: 100000;
  padding: 8px 12px;
  top: 12vh;
  left: auto;
  right: auto;
  pointer-events: none;
  box-shadow: 0px 32px 64px 0px rgba(0, 0, 0, 0.07),
    0px 16px 32px 0px rgba(0, 0, 0, 0.07), 0px 8px 16px 0px rgba(0, 0, 0, 0.07),
    0px 4px 8px 0px rgba(0, 0, 0, 0.07), 0px 2px 4px 0px rgba(0, 0, 0, 0.07),
    0px 1px 2px 0px rgba(0, 0, 0, 0.07);
  transform: translateY(60%);
  opacity: 0;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.notificationBlock.active {
  transform: translateY(0%);
  opacity: 1;
  transition: transform 0.4s cubic-bezier(0.68, 0.68, 0.43, 1.51),
    opacity 0.4s cubic-bezier(0.68, 0.68, 0.43, 1.51);
  pointer-events: all;
}

.stackOne {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  width: 100%;
}

.stackTwo {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.titleNotification {
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  font-family: "AvenirNextCyr";
  color: #ff9500;
}

.textNotification {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #ff9500;
}

.success {
  color: #149527;
}

.error {
  color: #ba1a1a;
}

.notificationBlock__close {
  position: absolute;
  top: 4px;
  right: 6px;
  cursor: pointer;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
