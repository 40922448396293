.mainMapBlock {
    overflow: auto;
    position: relative;
    margin-bottom: 8px;
    padding-bottom: 10px;
    &::-webkit-scrollbar {
        height: 2px;
    }

    &::-webkit-scrollbar-track {
        background-color: #E4DFFF;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #8F86DE;
        border-radius: 4px;
    }
}

.overflowBlock {
    width: fit-content;
}

.mainTitle {
    color: #000;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
}

.bottomP {
    display: flex;
    margin-top: 8px;
    width: 100%; 
    justify-content: space-between; 
}