.checkbox {
  display: none;
}

.switch {
  width: 100%;
  max-width: 272px;
  height: 40px;
  background: #5c53a7;
  z-index: 0;
  margin: 0;
  padding: 0;
  appearance: none;
  border: none;
  cursor: pointer;
  position: relative;
  border-radius: 360px;
  transition: 0.3s;

  p {
    position: absolute;
    z-index: 2;
    color: #fff;
    top: 5px;
    left: 46px;
    font-family: Raleway;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    transition: 0.3s;
  }

  &::before {
    content: " ";
    position: absolute;
    left: 1px;
    top: 1px;
    width: 100%;
    max-width: 272px;
    height: 40px;
    border-radius: 360px;
    background: #5c53a7;
    z-index: 1;
    transition: 0.3s;
  }

  &::after {
    content: " ";
    height: 33px;
    width: 33px;
    border-radius: 50%;
    background: radial-gradient(
      circle closest-side,
      #fff 14px,
      transparent 15px
    );
    border: 2px solid #2d2276;
    position: absolute;
    z-index: 2;
    top: 2px;
    left: 2px;
    transition: 0.3s;
  }
}

.switchOn {
  p {
    left: 0;
    transform: translate(15%, 0);
    transition: 0.3s;
  }
}

.switch.switchOn {
  background: #3c347c;
  transition: 0.3s;

  &::before {
    background: #3c347c;
    transition: 0.3s;
  }

  &::after {
    left: 99%;
    transform: translate(-100%, 0);
    transition: 0.3s;
  }
}

@media (min-width: 567.9px) {
  .switch {
    width: 182px;
    &::before {
      width: 182px;
    }
  }
}
