.containerFormTemplate {
    display: grid;
    grid-template-rows: 1fr 1fr; /* Two rows */
    grid-template-columns: repeat(4, 1fr); /* Three columns for the first row */
    gap: 10px; /* Optional: space between grid items */
    padding: 10px;
}

.item {
    grid-column: span 2; /* Each item in the second row will take one column */
}

.item > div {
    width: 100%;
}

@media (max-width: 720px) {
    .containerFormTemplate {
        display: flex;
        flex-direction: column;
    }
}