html {
	scroll-behavior: smooth;
}

.landing__notification {
	width: 100%;
	display: flex;
	justify-content: center;
}

.landingBg {
	background-image: url('../../assets/images/backImg.png');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
}

.footerBg {
	background-image: linear-gradient(0deg, rgba(28, 28, 30, 0.72) 0%, rgba(28, 28, 30, 0.72) 100%), url('../../assets/images/footer.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
}

.landing__container {
	width: 100%;
	max-width: calc(100% - 24px);
	margin-right: auto;
	margin-left: auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-sizing: border-box;

	@media (min-width: 567.9px) {
		max-width: calc(100% - 28px);
	}

	@media (min-width: 743.9px) {
		max-width: calc(100% - 48px);
	}

	@media (min-width: 1023.9px) {
		max-width: calc(100% - 64px);
	}

	@media (min-width: 1281px) {
		max-width: calc(100% - 140px);
	}

	@media (min-width: 1439.9px) {
		max-width: 1320px;
	}
}

.landing__col {
	padding: 24px 0 40px 0;
	display: flex;
	flex-direction: column;
	gap: 24px;
	margin-top: 105px;

	@media (min-width: 567.9px) {
		padding: 40px 0 80px 0;
		gap: 40px;
		margin-top: 134px;
	}
}

.landing__row {
	display: flex;
	flex-direction: column;
	gap: 48px;

	@media (min-width: 567.9px) {
		gap: 24px;
	}

	@media (min-width: 1281px) {
		flex-direction: row;
		align-items: flex-start;
		gap: 48px;
	}
}
