.hero {
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(4px);
  padding: 12px;

  @media (min-width: 567.9px) {
    padding: 20px;
  }

  @media (min-width: 743.9px) {
    padding: 32px;
  }
}

.hero__title {
  color: #170362;
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 24px;
  letter-spacing: 0.04px;

  @media (min-width: 567.9px) {
    font-size: 48px;
    line-height: 56px;
  }
}

.hero__descr {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;

  p {
    color: #1c1c1e;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.02px;
  }
}

.hero__sub_title {
  color: #5A479F;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 16px;
}

.hero__btns {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;

  a {
    width: 100%;
  }

  button {
    width: 100%;
    padding: 17px 12px;
  }

  @media (min-width: 567.9px) {
    flex-direction: row;

    a {
      width: auto;
    }

    button {
      width: auto;
      padding: 17px 24px;
    }
  }
}
