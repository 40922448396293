.scheduleContainer {
	display: flex;
	align-items: center;
	gap: 8px;
}

.scheduleTitle {
	display: flex;
	align-items: center;
	min-width: 144px;
}

.scheduleInputs {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 8px;
	align-items: center;
	max-width: 260px;
}
