.menuDesktop {
	padding: 8px;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: #ffffff;
	overflow-x: hidden;
	overflow-y: auto;
	border-right: 1px solid #E6EFF5;

	// скролл
	&::-webkit-scrollbar {
		width: 2px;
	}

	&::-webkit-scrollbar-track {
		background-color: #ebeef4;
		border-radius: 2px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #cdd2dc;
		border-radius: 2px;
	}
}

.menuDesktop__list {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.menuDesktop__item {
	a,
	.menuDesktop__item_openModal {
		border-radius: 8px;
		padding: 8px;
		cursor: pointer;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 8px;
		color: #939dac;
		font-family: 'Inter';
		font-size: 14px;
		font-weight: 400;
		line-height: 16px; 

		&:hover {
			svg {
				path {
					fill: #5856d6; 
				} 
			}
			color: #5856d6;
		}

		&.activeLink {
			svg {
				path {
					fill: #5856d6;
				}
			}
			color: #5856d6;
		}
	}
}

.menuDesktop__subMenu {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.subMenu__title {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	color: #939dac;
	cursor: pointer;

	> div {
		padding: 8px;
		display: flex;
		flex-direction: row;
		gap: 8px;
		align-items: center;
		color: #939dac;
		font-family: 'Inter';
		font-size: 14px;
		font-weight: 400;
		line-height: 16px;

		&:hover {
			svg { 
				path {
					fill: #5856d6;
				}
			}
			color: #5856d6;
		}

		&.activeLink {
			svg {
				path {
					fill: #5856d6;
				}
			}
			color: #5856d6;
		}
	} 
}

.subMenu__list {
	padding: 0px 12px 12px 12px;
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.menuDesktop__other {
	padding: 4px;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	gap: 4px;
	background: #ffffff;
}

.arrowUp {
	transform: rotate(180deg);
}

.menuDesktop__item_dnone {
	display: none;
}

.modalSupport {
	display: flex;
	flex-direction: column;
	gap: 12px;

	label {
		font-size: 20px;
		font-weight: 600;
		line-height: 28px;
		margin: 8px 0;
	}

	textarea {
		border: 1px solid #443a8e;
		cursor: pointer;
		min-height: 100px;
		width: 100%;
		padding: 8px;
		background-color: #fffbff;
		border-radius: 8px;
		font-size: 16px;
		line-height: 20px;
	}
}

.modalSupport__btns {
	width: 100%;
	display: flex;
	justify-content: flex-end;
}

.dropzone {
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
	align-items: center;
}

.uploadImageWrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 8px;

	&.miniUploadImageWrapper {
		width: 106px;
		height: 106px;
	}
}

.uploadButton {
	cursor: pointer;
	color: #1c1c1e;
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.08px;
	display: flex;
	gap: 8px;
	align-items: center;
	padding: 40px 8px;
	width: 100%;
	height: 100%;
	justify-content: center;
}

[class*='viewer-container'] {
	z-index: 100000 !important;
}

.photoContainer {
	border-radius: 8px;
	border: 2px dashed #aeaeb2;
	display: flex;
	height: 172px;
	min-height: 64px;
	justify-content: center;
	align-items: center;
	width: 100%;
	gap: 8px;
	align-self: stretch;
}

.viewer {
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
}

.onePhotoCard {
	position: relative;
	max-height: 106px;

	& > img {
		max-width: 106px;
		aspect-ratio: 1/1;
		border-radius: 5px;
	}
	& > :last-child {
		position: absolute;
		left: 3px;
		bottom: 3px;
	}
}

.problem__notification {
	display: flex;
	align-items: center;
	justify-content: center;
}

.btnHide {
	position: absolute;
	left: 74px;
	top: 35px;
	width: 28px;
	height: 28px;
	border: 1px solid #939dac;
	border-radius: 8px;
	background-color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	opacity: 0;
	transition: opacity 0.2s ease;
	z-index: 100000;

	&.open {
		left: 240px;
	}

	&.visible {
		opacity: 1;
		transition: opacity 0.2s ease;
	}
}

.arrowLeft {
	transform: rotate(90deg);
}

.arrowRight {
	transform: rotate(270deg);
}
