.account__container {
	height: calc(100% - 94px);
	flex-direction: column;
	min-height: 100%;
	flex-grow: 1;
	justify-content: space-between;

	@media (min-width: 1139.9px) {
		height: calc(100% - 80px);
	}
}

.account__stack {
	height: 100%;
	background: #f5f7fa;
	display: flex;
	flex-direction: column;
	overflow-y: auto;

	> div:last-child {
		margin-bottom: 140px;
	}
}

.block {
	border-radius: 12px;
	margin: 16px 16px 0 16px;
	background: #ffffff;
	padding: 12px;
}

.form {
	display: grid;
	grid-template-rows: 1fr 1fr;
	grid-template-columns: repeat(4, 1fr);
	gap: 10px;
	padding: 16px;

	> div {
		grid-column: span 2;
	}

	@media (max-width: 719.9px) {
		padding: 4px;
		grid-template-columns: repeat(2, 1fr);
	}
}

.formCol {
	padding-left: 16px;
	display: flex;
	flex-direction: column;
	gap: 6px;
	width: 50%;

	@media (max-width: 719.9px) {
		padding-left: 4px;
		width: 100%;
	}
}

.viewers {
	margin-top: 12px;
	display: flex;
	gap: 12px;
	flex-direction: column;
}

.viewerContainer {
	display: flex;
	gap: 8px;
	flex-direction: column;
}

.viewerTitle {
	font-size: 18px;
}

.viewerNone {
	font-size: 12px;
}

.viewer {
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
}

.onePhotoCard {
	position: relative;
	max-height: 106px;
}

.onePhotoCard img {
	max-width: 106px;
	aspect-ratio: 1/1;
	border-radius: 5px;
}

.onePhotoCard > :last-child {
	left: 3px;
	bottom: 3px;
}
