.content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-x: scroll;
    position: relative;
} 

.steps {
    display: flex;
    position: relative;
    flex-grow: 1;
    flex-direction: column;
}