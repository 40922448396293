.btnText {
  border-radius: 8px;
  padding: 14px 16px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.08px;
  cursor: pointer;
  background: #5c53a7;
  color: #fffbff;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #443a8e;
  }

  &:active {
    background: #2d2276;
  }

  &:disabled {
    background: #8e8e93;
  }
}
.blue {
  background: #2d2276;
  color: #ffffff;

  &:hover {
    background: #261c67;
  }

  &:active {
    background: #21185b;
  }

  &:disabled {
    background: #8e8e93;
  }
}

.space {
  justify-content: space-between !important;
}

.white {
  background: transparent;
  border: 1px solid #fffbff;
  color: #fffbff;

  &:hover {
    background: transparent;
    border: 1px solid #dadada;
    color: #dadada;
  }

  &:active {
    background: transparent;
    border: 1px solid #bfbfbf;
    color: #bfbfbf;
  }

  &:disabled {
    background: transparent;
    border: 1px solid #8e8e93;
    color: #8e8e93;
  }
}

.orange {
  background: #ff8e16;
  color: #ffffff;

  &:hover {
    background: #d77813;
  }

  &:active {
    background: #d77813;
  }

  &:disabled {
    background: #8e8e93;
  }
}

.js-between {
  justify-content: space-between;
}

.p-1216 {
  padding: 12px 12px 12px 16px;
  gap: 12px;
}
.p-1624 {
  padding: 16px 24px;
}

.p-1724 {
  padding: 17px 24px;
}

.p-810 {
  padding: 8px 10px;
}

.fs-14 {
  font-size: 14px;
  line-height: 20px;
}

.fs-18 {
  font-size: 18px;
  line-height: 22px;
}

.fs-20 {
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
}

.toMainInfo div {
  transform: rotate(90deg) !important;
}

.secondary {
  background: transparent;
  border: 1px solid #5c53a7;
  color: #5c53a7;
  transition: 0.3s;

  &:hover {
    background: transparent;
    border: 1px solid #443a8e;
    color: #443a8e;
  }

  &:active {
    background: transparent;
    border: 1px solid #2d2276;
    color: #2d2276;
  }

  &:disabled {
    background: transparent;
    border: 1px solid #8e8e93;
    color: #8e8e93;
  }
}

.delete {
  background: #ba1a1a;
  color: #fffbff;
  transition: 0.3s;

  &:hover {
    background: #9c1313;
  }

  &:active {
    background: #860f0f;
  }

  &:disabled {
    background: #8e8e93;
  }
}

.save {
  background: #ff8e16;
}

.code {
  background: transparent;
  border: 1px solid #5c53a7;
  color: #5c53a7;
  transition: 0.3s;

  &:hover {
    background: transparent;
    border: 1px solid #443a8e;
    color: #443a8e;
  }

  &:active {
    background: transparent;
    border: 1px solid #2d2276;
    color: #2d2276;
  }

  &:disabled {
    background: transparent;
    border: 1px solid #5c53a7;
    color: #5c53a7;
  }
}

.green {
  background: #149527;
  transition: 0.3s;

  &:hover {
    background: #0f7c1f;
  }

  &:active {
    background: #0b6b19;
  }

  &:disabled {
    background: #8e8e93;
  }
}

.grey {
  background: #535f70;
  color: #fffbff;

  &:hover {
    background: #444e5c;
  }

  &:active {
    background: #39424d;
  }

  &:disabled {
    background: #8e8e93;
  }
}
