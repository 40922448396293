.account__container {
	height: calc(100% - 94px);
	flex-direction: column;
	min-height: 100%;
	flex-grow: 1;
	justify-content: space-between;

	@media (min-width: 1139.9px) {
		height: calc(100% - 80px);
	}
}

.account__stack {
	height: 100%;
	background: #ffffff;
	display: flex;
	flex-direction: column;
	overflow-y: auto;

	> div:last-child {
		margin-bottom: 140px;
	}
} 

.form {
	display: grid;
	grid-template-rows: 1fr 1fr;
	grid-template-columns: repeat(4, 1fr);
	gap: 10px;
	padding: 16px;

	> div {
		grid-column: span 2;
	}

	@media (max-width: 719.9px) {
		padding: 8px;
		grid-template-columns: repeat(2, 1fr);
	}
}