.content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
} 

.tabs {
    margin: 0 16px;
    display: flex;
    gap: 8px;
}

.steps {
    display: flex;
    position: relative;
    flex-grow: 1;
    flex-direction: column;
}