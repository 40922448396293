.checkbox {
	> div {
		border: 1px solid #5c53a7;
		width: 26px;
		height: 26px;
		border-radius: 50%;
		cursor: pointer;
	}
}

.checkboxType2 {
	> div {
		border-radius: 6px !important;
	}
}

.check {
	display: flex;
	align-items: center;
	justify-content: center;
}

.bg {
	width: 22px;
	height: 22px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #5c53a7;
}
.disabled {
	background: #959496;
	cursor: not-allowed !important;
}
.disabled_border {
	border: 1px solid #959496 !important;
	cursor: not-allowed !important;
}
.bgType2 {
	border-radius: 6px !important;
}
.noCheck {
	border: 1px solid #5c53a7;
}
.optionalVariants {
	display: flex;
	flex-direction: row;
}
