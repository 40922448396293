.wrap {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	justify-content: center;
	height: 100svh; 
}

.container {
	display: flex;
	flex-direction: column;
	gap: 6px;
	width: 100%;
	justify-content: center;
	max-width: 360px;

	@media (max-width: 743.9px) {
		margin-top: 20px;
		max-width: 100%;
	}
}

.modal {
	border-radius: 12px;
	background: #fffbff; 
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 4px;

	@media (max-width: 743.9px) {
		overflow-y: auto;
	}
}

.modalContainer {
	display: flex;
	flex-direction: column;
	gap: 12px;
	max-height: calc(100vh - 220px);
	overflow-y: auto;
	padding: 10px;

	p {
		text-align: center;
	}

	&::-webkit-scrollbar {
		width: 2px;
	}

	&::-webkit-scrollbar-track {
		background-color: #e4dfff;
		border-radius: 2px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #8f86de;
		border-radius: 2px;
	}
}

.modalTitle {
	font-size: 20px;
}

.modalBlock {
	display: flex;
	flex-direction: column;
	gap: 6px;
}

.textCode {
	font-size: 12px;
	color: #5c53a7;
	cursor: pointer;
}

.modalFooter {
	width: 100%;
	padding: 10px;
	display: flex;
	justify-content: flex-end;
}

.modalStack {
	margin-top: 8px;
	display: flex;
	flex-direction: column;
	gap: 6px;

	button {
		width: 100%;
	}

	a {
		width: 100%;
	}
}

.modalRow {
	margin-top: 8px;
	display: flex;
	gap: 12px;
	justify-content: center;
}

.btnBack {
	background-color: #fffbff;
	width: 36px;
	height: 36px;
	padding: 10px;
	border-radius: 8px;
}
